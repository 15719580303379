<template>
  <q-dialog v-if="!myCampaignId" v-model="dialogCampaign">
      <my-campaign-details :toolbar="true" @close="onClose" />
    </q-dialog>

    <q-card v-else flat>
    <my-campaign-details :myCampaignId="myCampaignId" />
  </q-card>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import AffiliateService from '../../services/AffiliateService';
import ExtraServices from '../../services/ExtraServices';
import AdvertiserService from '../../services/AdvertiserService';
import MyCampaignDetails from '@/components/campaign/MyCampaignDetails.vue';

export default {
  name: 'FormMyCampaign',
  components: { MyCampaignDetails },
  mixins: [CampaignMixin, AffiliateService, ExtraServices, AdvertiserService],
  props: {
    myCampaignId: {
      type: String,
      default: null,
      required: false
    }
  },


  methods: {
    onClose() {
      this.ActionSetDialogCampaign(false);
      this.ActionSetCampaignSelect({});
    },


  },

};
</script>
