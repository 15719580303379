<template>
  <q-card bordered flat class="q-mb-md">
    <q-card-section class="row q-col-gutter-md">
      <div :class="type == 'mycampaigns' ? 'col-6' : 'col-3'" v-if="filters.find((item) => item === 'channel')">
        <q-select
          :loading="loadChannels"
          dense
          v-model="params.channel"
          label="Canal de divulgação"
          outlined
          use-input
          input-debounce="200"
          @filter="filterChannel"
          :options="channelsFiltered"
          option-label="name"
          option-value="id"
          clear-icon="mdi-close"
          clearable
          @input="getCampaignsByChannel(params.channel)"
          @clear="getCampaigns()"
        />
      </div>
      <div :class="type == 'mycampaigns' ? 'col-6' : 'col-3'" v-if="filters.find((item) => item === 'campaign')">
        <q-select
          :loading="loadCampaigns"
          :disable="type == 'mycampaigns' && !params.channel"
          v-model="params.campaign"
          :label="$t('campaigns')"
          outlined
          dense
          use-input
          input-debounce="200"
          :options="campaignsFiltered"
          option-label="name"
          option-value="id"
          clear-icon="mdi-close"
          clearable
          @filter="filterCampaignSelect"
        />
      </div>
      <div class="col-3" v-if="filters.find((item) => item === 'category')">
        <q-select
          :loading="loadCategory"
          dense
          v-model="params.category"
          label="Categoria"
          outlined
          use-input
          input-debounce="200"
          @filter="filterCategory"
          :options="categoriesFiltered"
          option-label="name"
          option-value="id"
          clear-icon="mdi-close"
          clearable
        />
      </div>
      <div class="col-3" v-if="filters.find((item) => item === 'type')">
        <q-select
          dense
          v-model="params.type"
          :options="['cpa', 'cpc', 'cpl']"
          label="Tipo de Comissão"
          outlined
          clear-icon="mdi-close"
          clearable
        />
      </div>
      <div class="col-3" v-if="filters.find((item) => item === 'period')">
        <q-select
          dense
          v-model="params.period"
          label="Período de Pagamento"
          outlined
          clear-icon="mdi-close"
          clearable
        />
      </div>
      <div class="col-12 text-right">
        <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
      </div>
      <div class="col-12 text-center q-gutter-sm" v-if="status">
        <q-chip v-for="(status, key) in statusFilterArr" :key="key" square :color="status.chipColor" text-color="white">
          <q-avatar :color="status.avatarColor" text-color="white">
            <q-checkbox v-model="params.status" :val="key" dense :color="status.avatarColor" dark />
          </q-avatar>
          {{ status.quantity }}
          {{ $t(status.name) }}
        </q-chip>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  name: 'HeaderMyCampaign',
  mixins: [CampaignMixin, AdvertiserService],
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'allcampaigns'
    },
    channelsList: [],
    categoriesList: [],
    campaignsAllLocal: [],
  },

  data() {
    return {
      loadChannels: false,
      loadCampaigns: false,
      loadCategory: false,
      filter: true,
      status: false,
      campaignSiteStatus: [],
      params: {
        fixedStatus: false,
        channel: null,
        category: null,
        type: null,
        campaing: null,
        period: null,
        status: []
      },
      campaingsStatus: [],
      campaignsFiltered: [],
      categoriesFiltered: [],
      channelsFiltered: [],
      campaignsList: [],
      statusFilterArr: [
        {
          name: 'Pending',
          chipColor: 'yellow-7',
          avatarColor: 'yellow-8',
          quantity: 0
        },
        {
          name: 'Approved',
          chipColor: 'green-5',
          avatarColor: 'green-6',
          quantity: 0
        },
        {
          name: 'Paused',
          chipColor: 'deep-purple-5',
          avatarColor: 'deep-purple-6',
          quantity: 0
        },
        {
          name: 'Reproved',
          chipColor: 'pink-3',
          avatarColor: 'pink-4',
          quantity: 0
        },
        {
          name: 'Removed',
          chipColor: 'grey-9',
          avatarColor: 'dark',
          quantity: 0
        },
        {
          name: 'Non Related',
          chipColor: 'light-blue-3',
          avatarColor: 'light-blue-4',
          quantity: 0
        }
      ]
    };
  },
  methods: {
    filterCampaignSelect(val, update) {
      update(this.defaultFilter(val, 'campaignsFiltered', this.campaignsList, 'name'));
    },
    filterChannel(val, update) {
      update(this.defaultFilter(val, 'channelsFiltered', this.channelsList, 'name'));
    },
    filterCategory(val, update) {
      update(this.defaultFilter(val, 'categoriesFiltered', this.categoriesList, 'name'));
    },
    async getCampaignsByChannel(channel) {
      if (channel && channel.id) {
        try {
          const { data, status } = await this.$http.get(
            `v2/redirect?app=advertiser&path=/api/get/campaign/approved/filter/site/${channel.id}`
          );
          if (status === 200) {
            this.campaignsList = data;
            this.campaignsFiltered = data;
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        }
      } else this.getCampaigns;
    },

    getCampaigns() {
      this.campaignsList = this.campaignsAllLocal;
      this.campaignsFiltered = this.campaignsAllLocal;
    },

    clearFilter() {
      this.params = {
        channel: null,
        category: null,
        type: null,
        campaing: null,
        period: null,
        status: []
      };
      if (this.type == 'mycampaigns')
        this.ActionSetFilterCampaign({
          affiliate_id: this.user.affiliate_id
        });
      else this.ActionSetFilterCampaign();
      this.getCampaigns();
      this.getMyCampaigns();
    },
    async onFilterCampaigns() {
      let filterSelect = {};
      if (this.type == 'mycampaigns') filterSelect.mycampaigns = true;
      if (this.params.channel) filterSelect['site_id'] = this.params.channel.id;
      if (this.params.campaign) filterSelect['campaign_id'] = this.params.campaign.id;
      if (this.params.category) filterSelect['category_id'] = this.params.category.id;
      if (this.params.type) filterSelect['comission_type'] = this.params.type;
      if (this.params.status.length > 0) filterSelect['campaign_site_status'] = this.params.status.join(',');
      this.ActionSetFilterCampaign(filterSelect);
      this.getMyCampaigns();
    }
  },
  watch: {
    filterCampaign: {
      handler(value) {
        this.status = value?.site_id && this.type !== 'mycampaigns' ? true : false;
      },
      deep: true
    },

    campaignsSummary: {
      handler(summary) {
        this.statusFilterArr.forEach((status, key) => {
          const sum = summary.find((sum) => sum.campaign_site_status == key);
          status.quantity = sum ? sum.quantidade : 0;
        });
      }
    },

    campaignsAllLocal() {
      this.getCampaigns();
    },

    campaigns: {
      handler() {
        this.campaingsStatus = this.campaigns.map((item) => item.campaign_site_status);
      },
      deep: true
    },

    params: {
      handler() {
        this.onFilterCampaigns();
      },
      deep: true
    }
  },
    created() {
        this.getCampaigns();
    }
};
</script>
